import { Component, OnInit } from '@angular/core';
import { ROUTES } from '../../app.routes';
import { Router } from '@angular/router';
//import {AuthService} from '../../services/auth.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  //styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {

  constructor( ) {
    //auth.handleAuthentication();


  }


  ngOnInit() {
    window.scrollTo(0, 0);
  }




}
