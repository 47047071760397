import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-iroute',
  templateUrl: './iroute.component.html',
  //styleUrls: ['./iroute.component.css']
})
export class IrouteComponent implements OnInit {

  constructor() { }

  ngOnInit() {
    window.scrollTo(0, 0);
  }

}
