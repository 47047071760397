import { IrouteComponent } from './components/iroute/iroute.component';
import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './components/home/home.component';
import { CorporativoComponent} from './components/corporativo/corporativo.component';
import { ServiciosComponent} from './components/servicios/servicios.component';
import { ProductosComponent} from './components/productos/productos.component';
import { NoticiasComponent} from './components/noticias/noticias.component';
import { ContactoComponent} from './components/contacto/contacto.component';


//import {AuthService} from './services/auth.service';


export const ROUTES: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'corporativo', component: CorporativoComponent },
    { path: 'servicios', component: ServiciosComponent },
    { path: 'productos', component: ProductosComponent },
    { path: 'noticias', component: NoticiasComponent },
    { path: 'contacto', component: ContactoComponent },
    { path: 'iroute', component: IrouteComponent },
    { path: '**', pathMatch: 'full', redirectTo: 'home' }
];

