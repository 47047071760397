import { Component, OnInit,ViewChild,ElementRef } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ViajesService } from '../../services/viajes.service';

import * as $ from 'jquery';

@Component({
  selector: 'app-contacto',
  templateUrl: './contacto.component.html',
  //styleUrls: ['./contacto.component.css']
})
export class ContactoComponent implements OnInit {
  myForm : FormGroup;
  @ViewChild('captchaRef2') captchaRef2: ElementRef;
  @ViewChild('nombre') nombreRef: ElementRef;
private _reCaptchaId: number;
private captcha:boolean;
private SITE_ID = "6LeFmaYUAAAAAObn1xJ2CGFVCyN9jCB0rI_QLJ_o";

  constructor(public _vs:ViajesService,public fb: FormBuilder) {
    console.log("constructor");
this.captcha=false;
   /* this.myForm = fb.group({

      'nombre' : [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
      'mail' : [null, Validators.compose([Validators.required, Validators.email])],
      'asunto' :[null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(50)])],
      'mensaje' :[null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(100)])],
    });
*/
    this.myForm = fb.group({
      //    'id_usuario' : [null,  Validators.compose([Validators.required, Validators.minLength(28), Validators.maxLength(28)])],
          'nombre' : [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(15)])],
          'mail' : [null, Validators.compose([Validators.required, Validators.email])],
          'mensaje' : [null, Validators.compose([Validators.required, Validators.minLength(10), Validators.maxLength(100)])],
          'asunto' : [null, Validators.compose([Validators.required, Validators.minLength(5), Validators.maxLength(12)])],
          'fono' : [null, Validators.compose([Validators.required, Validators.minLength(9), Validators.maxLength(11)])],



        })

   }

   ngAfterViewInit() {
     console.log("ngAfterViewInit");
    const grecaptcha = (window as any).grecaptcha;
    if (grecaptcha) {
      this._reCaptchaId = grecaptcha.render(this.captchaRef2.nativeElement, {
        'sitekey': this.SITE_ID,
        'callback': (resonse) => this.reCapchaSuccess(resonse),
        'expired-callback': () => this.reCapchaExpired()
      });
    }
    this.nombreRef.nativeElement.focus();
}

reCapchaSuccess(data:any){
  if(data){
    alert("Congratulation! reCAPTCHA verified.")
    // Some logic goes here
    this.captcha=true;

  }
}

reCapchaExpired(){
  alert("Oops! reCAPTCHA expired.")
  // Some logic goes here
  this.captcha=false;
}


  ngOnInit() {
    console.log('ngOnInit');
    window.scrollTo(0, 0);

  }
  submitForm(form: any): void{
    console.log('Form Data: ');
    console.log(form);
    if(this.captcha){

    this._vs.guarda_nuevo_contacto(form).then(data=>{
      console.log("dataresponse=",data);
          if(data){
            console.log("Contacto guarado exitosamente");
            this.limpiar();
          }
          else{
            console.log("Problemas al guardar Contacto");
          }

    })
  }
  else{
    console.log("Debe Verificar Captcha");
    alert("Favor Verificar Captcha");
    return;

  }



  }

  limpiar(){
    console.log("limpiar");
        $("#nombre").val("");
        $("#mail").val("");
        $("#asunto").val("");
        $("#mensaje").val("");
        $("#fono").val("");
        this.captcha=false;

        this.myForm.reset();
        (window as any).grecaptcha.reset();



  }
}
